@tailwind base;
@tailwind components;
@tailwind utilities;

.example::-webkit-scrollbar {
  display: none;
}

.example {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#root::-webkit-scrollbar {
  display: none;
}

#root {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

html {
  scroll-behavior: smooth;
}

.wordSpace {
  word-spacing: 5px;
}

.textSelection::selection {
  background-color: rgb(255, 111, 0);
  color: black;
  /* Text color when selected */
}

.textSelection::-moz-selection {
  background-color: rgb(255, 183, 0);
  color: black;
  /* Text color when selected */
}

.active {
  background-color: rgb(0, 255, 255);
}

.shadow-inner {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}

@keyframes brightnessAnimation {
  50% {
    filter: brightness(0.5);
  }
}

.storyAnimation {
  animation: brightnessAnimation 5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes ping {

  75%,
  100% {
    transform: scale(1.5);
  }
}

.animatePing {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}

/* Hide scrollbar for Chrome, Safari, and Edge */
::-webkit-scrollbar {
  display: none;
}

.grid {
  background-color: black;
}


@keyframes introAnimation {
  50% {
    opacity: .8;
  }
}

.introAnimation {
  animation: introAnimation 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.inrto {
  background: -webkit-linear-gradient(top left, #6e8de0, #4b6bc6 50%, #2a3f88);
  background: -moz-linear-gradient(top left, #6e8de0, #4b6bc6 50%, #2a3f88);
  background: -ms-linear-gradient(top left, #6e8de0, #4b6bc6 50%, #2a3f88);
  background: linear-gradient(to bottom right, #6e8de0, #4b6bc6 50%, #2a3f88);
  background-color: #6e8de0;
}

.transition-colors {
  display: none;
}

.card {
  backdrop-filter: blur(10px) saturate(200%);
  -webkit-backdrop-filter: blur(10px) saturate(200%);
  background-color: rgba(160, 160, 165, 0);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

/* Apply these styles globally to all inputs affected by Chrome's autofill */
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  box-shadow: 0 0 0 1000px white inset !important;
  -webkit-text-fill-color: #000 !important;
}

/* Ensure consistent text color and font after autofill */
input:-webkit-autofill::first-line {
  font-family: inherit !important;
  color: inherit !important;
  font-size: inherit !important;
}

@keyframes swing {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-30deg);
  }

  75% {
    transform: rotate(30deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.swing-hand {
  display: inline-block;
  animation: swing 2s ease-in-out infinite;
  transform-origin: 50% 100%;
  /* Set rotation around the bottom-center of the hand */
}

* {
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}

*::-webkit-scrollbar {
  display: none;
  /* For Chrome, Safari, and Opera */
}

.vertical-letters {
  display: inline-block;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  /* Rotates entire text block */
  text-orientation: mixed;
  /* Rotates individual letters */
}

.slick-dots li button::before {
  margin-top: 2px;
  background: #0a0a0a;
  height: 5px;
  width: 5px;
  scale: 0.5;
  border-radius: 50%;

}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animatePulseChat {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes spinY {
  0% { transform: rotateY(0deg); }
  100% { transform: rotateY(360deg); }
}

.messageTickSpin {
  animation: spinY 1s linear 1;
}
